<template>
  <div>
    <video ref="videoPlayer" controls autoplay></video>
  </div>
</template>

<script>
import axios from "axios";
import api from "@/services/api";
import dashjs from "dashjs";
import store from "@/store";
export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    isPrivate: Boolean,
  },
  mounted() {
    // Initialize DASH player
    this.initializePlayer(this.src);
  },
  watch: {
    src(newValue) {
      // Re-initialize the DASH player when the src prop changes
      this.initializePlayer(newValue);
    },
  },
  methods: {
    initializePlayer(src) {
      if (this.player) {
        this.player.reset();
      }

      this.player = dashjs.MediaPlayer().create();
      this.player.initialize(this.$refs.videoPlayer, src);
      this.playerEventWatcher();
    },
    playerEventWatcher() {
      this.player.on(dashjs.MediaPlayer.events["PERIOD_SWITCH_STARTED"], () => {
        if (this.interval) {
          clearInterval(this.interval);
        }
        if (this.isPrivate) {
        this.interval = setInterval(() => {
          this.authenticateUser();
        }, 10000);
        }
      });
    },
    authenticateUser() {
      axios.post(api.getUrl("/check"),{userId:store.getters.user.id});
    },
  },
  beforeDestroy() {
    // Clean up DASH player when component is destroyed
    if (this.player) {
      this.player.reset();
    }
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
};
</script>

<style>
/* Add any custom styles here */
</style>
